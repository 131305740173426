<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: page_route,
              params: { contractor_id: contractor_id },
            }"
          />
          <span
            class="text-h6 ml-1"
            v-text="
              `${loading_contractor ? '' : `${contractor.name} | `}${
                $route.meta.title
              }`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip v-if="data" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                x-small
                class="ml-1"
                :color="
                  (data.insured_revision_pending ? 'orange' : 'grey') +
                  ' darken-1'
                "
                dark
                fab
                @click.prevent="insuredRevisionsDialog"
              >
                <v-icon> mdi-folder-multiple </v-icon>
              </v-btn>
            </template>
            <span v-text="'Revisiones'" />
          </v-tooltip>
          <v-tooltip v-if="data" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                x-small
                class="ml-1"
                :color="
                  (data.medical_treatment ? 'pink ' : 'grey') + ' darken-1'
                "
                dark
                fab
                @click.prevent="insuredMedicalTreatmentHandle"
              >
                <v-icon> mdi-shield-account </v-icon>
              </v-btn>
            </template>
            <span
              v-text="
                (data.medical_treatment ? 'Con' : 'Sin') + ' tratamiento médico'
              "
            />
          </v-tooltip>
          <BtnCircle
            v-if="login.permissions.insureds.update"
            tLabel="Editar"
            tPosition="bottom"
            icon="mdi-pencil"
            color="info"
            size="x-small"
            :to="{ name: `${page_route}.update`, params: { id: id } }"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="data">
      <v-row>
        <v-col v-if="data.contractor_duplicated" cols="12">
          <v-alert text prominent dense type="error" icon="mdi-cloud-alert">
            ID SM y Número de asegurado duplicados en el contratante
            {{ data.contractor_duplicated }}
          </v-alert>
        </v-col>
        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'GENERAL'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Nombre" :value="data.name" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Apellido paterno"
                    :value="data.first_surname"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Apellido materno"
                    :value="data.second_surname"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Fecha de nacimiento"
                    :value="data.birthday"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Aseguradora" :value="data.insurance.name" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Tipo de asegurado"
                    :value="data.insured_type.insured_type"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Categoría"
                    :value="
                      data.insured_category ? data.insured_category.name : null
                    "
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col v-if="policyholder" cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'TITULAR'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Nombre" :value="policyholder.full_name" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Banco"
                    :value="policyholder.bank_id ? policyholder.bank.bank : '-'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Núm cuenta"
                    :value="
                      policyholder.account_number
                        ? policyholder.account_number
                        : ''
                    "
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="CLABE"
                    :value="policyholder.clabe ? policyholder.clabe : ''"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Cuenta bancaria (PDF)"
                    :value="
                      policyholder.bank_account_pdf &&
                      policyholder.bank_account_pdf != 'null'
                        ? null
                        : '-'
                    "
                  />
                  <v-btn
                    v-if="
                      policyholder.bank_account_pdf &&
                      policyholder.bank_account_pdf != 'null'
                    "
                    x-small
                    fab
                    outlined
                    class="ml-1"
                    color="warning"
                    :href="
                      url_documents +
                      '/insureds/' +
                      policyholder.bank_account_pdf
                    "
                    target="_blank"
                  >
                    <v-icon> mdi-download </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Valid. cuenta (PDF)"
                    :value="
                      policyholder.account_validation_pdf &&
                      policyholder.account_validation_pdf != 'null'
                        ? null
                        : '-'
                    "
                  />
                  <v-btn
                    v-if="
                      policyholder.account_validation_pdf &&
                      policyholder.account_validation_pdf != 'null'
                    "
                    x-small
                    fab
                    outlined
                    class="mr-1"
                    color="warning"
                    :href="
                      url_documents +
                      '/insureds/' +
                      policyholder.account_validation_pdf
                    "
                    target="_blank"
                  >
                    <v-icon> mdi-download </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'CONFIGURACIÓN'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="ID SM" :value="data.enrollment" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Póliza" :value="data.policy" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Número de asegurado"
                    :value="data.credential"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="8">
                  <ViewData
                    label="Nombre de oficina"
                    :value="data.office_address"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Vigencia" :value="data.validity" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Entidad"
                    :value="
                      data.entity_id ? data.entity.entity : 'NO REGISTRADO'
                    "
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Municipio"
                    :value="
                      data.municipality_id
                        ? data.municipality.municipality
                        : 'NO REGISTRADO'
                    "
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="RFC"
                    :value="data.code_tax ? data.code_tax : 'NO REGISTRADO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Suma potenciada"
                    :value="numberFormat(data.powered_amount)"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Suma asegurada"
                    :value="numberFormat(data.assured_amount)"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Antigüedad" :value="data.antiquity" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Usuario sistema"
                    :value="data.user.active ? 'SI' : 'NO'"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'BANCO'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Banco"
                    :value="data.bank_id ? data.bank.bank : '-'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Núm cuenta"
                    :value="data.account_number ? data.account_number : ''"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="CLABE"
                    :value="data.clabe ? data.clabe : ''"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Cuenta bancaria (PDF)"
                    :value="
                      data.bank_account_pdf && data.bank_account_pdf != 'null'
                        ? null
                        : '-'
                    "
                  />
                  <v-btn
                    v-if="
                      data.bank_account_pdf && data.bank_account_pdf != 'null'
                    "
                    x-small
                    fab
                    outlined
                    class="ml-1"
                    color="warning"
                    :href="url_documents + '/insureds/' + data.bank_account_pdf"
                    target="_blank"
                  >
                    <v-icon v-text="'mdi-download'" />
                  </v-btn>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Valid. cuenta (PDF)"
                    :value="
                      data.account_validation_pdf &&
                      data.account_validation_pdf != 'null'
                        ? null
                        : '-'
                    "
                  />
                  <v-btn
                    v-if="
                      data.account_validation_pdf &&
                      data.account_validation_pdf != 'null'
                    "
                    x-small
                    fab
                    outlined
                    class="mr-1"
                    color="warning"
                    :href="
                      url_documents + '/insureds/' + data.account_validation_pdf
                    "
                    target="_blank"
                  >
                    <v-icon v-text="'mdi-download'" />
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'REGISTRO'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Creación"
                    :value="!loading && data.created_by.email"
                    :subvalue="data.created_at"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="U. actualización"
                    :value="!loading && data.updated_by.email"
                    :subvalue="data.updated_at"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <BtnCircle
            v-if="$store.getters.getLogin.permissions.insureds.delete"
            tLabel="Desactivar"
            tPosition="top"
            icon="mdi-delete"
            color="error"
            size="x-small"
            :click="HandleDesactivate"
            :loading="loading"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog
      v-model="insured_revisions_dialog"
      scrollable
      persistent
      max-width="1800"
    >
      <v-card
        tile
        :disabled="insured_revisions_dialog_loading"
        :loading="insured_revisions_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> Revisiones </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="insured_revisions_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" />
            <v-col cols="12" v-if="data && !data.insured_revision_pending">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'REVISIÓN'" />
                </v-card-title>
                <v-divider />
                <v-card-text v-if="insured_revisions_data">
                  <v-form
                    v-on:submit.prevent
                    ref="insured_revisions_form"
                    lazy-validation
                  >
                    <v-row dense>
                      <v-col cols="12" xs="12" md="4">
                        <v-autocomplete
                          label="Banco"
                          v-model="insured_revisions_data.bank_id"
                          :rules="rules.required"
                          :items="banks"
                          item-value="id"
                          :item-text="(v) => v.bank"
                          :loading="banks_loading"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="Número de cuenta"
                          v-model="insured_revisions_data.account_number"
                          :rules="rules.required"
                          type="text"
                          dense
                          counter
                          maxlength="15"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="CLABE"
                          v-model="insured_revisions_data.clabe"
                          :rules="rules.required"
                          type="text"
                          dense
                          counter
                          maxlength="18"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-file-input
                          label="Cuenta bancaria (PDF)"
                          v-model="
                            insured_revisions_data.document_bank_account_0
                          "
                          :rules="rules.required"
                          accept=".pdf"
                          show-size
                          dense
                          prepend-icon=""
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-file-input
                          label="Valid. cuenta (PDF)"
                          v-model="
                            insured_revisions_data.document_account_validation_0
                          "
                          :rules="rules.required"
                          accept=".pdf"
                          show-size
                          dense
                          prepend-icon=""
                        />
                      </v-col>
                      <v-col cols="12" class="text-right">
                        <v-btn
                          color="info"
                          dark
                          @click.prevent="insuredRevisionStore"
                          small
                        >
                          Enviar
                          <v-icon right x-small> mdi-send </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" v-if="data && data.insured_revisions.length > 0">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'HISTORIAL'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row
                    v-for="(insured_revision, i) in data.insured_revisions"
                    :key="i"
                  >
                    <v-col cols="12" class="white--text">
                      <v-row
                        dense
                        :class="
                          (insured_revision.revision &&
                          insured_revision.confirmation &&
                          insured_revision.accepted
                            ? 'green'
                            : insured_revision.accepted == false &&
                              (insured_revision.revision ||
                                (insured_revision.revision &&
                                  insured_revision.confirmation))
                            ? 'red'
                            : 'grey') + ' darken-2'
                        "
                      >
                        <v-col
                          cols="12"
                          xs="12"
                          md="9"
                          class="font-weight-bold"
                        >
                          {{
                            `${i + 1}. ID ${insured_revision.id} | ${
                              insured_revision.created_at
                            } (${insured_revision.created_by.email}) | R1`
                          }}
                          {{
                            (insured_revision.revision != null
                              ? `${insured_revision.revision_date} (${insured_revision.revision_user.email})`
                              : "PENDIENTE") + " | R2"
                          }}
                          {{
                            insured_revision.confirmation != null
                              ? `${insured_revision.confirmation_date} (${insured_revision.confirmation_user.email})`
                              : "PENDIENTE"
                          }}
                        </v-col>
                        <v-col
                          v-if="
                            insured_revision.accepted == null ||
                            (insured_revision.revision &&
                              insured_revision.confirmation == null &&
                              insured_revision.accepted)
                          "
                          cols="12"
                          xs="12"
                          md="3"
                          class="text-right"
                        >
                          <v-tooltip left>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                fab
                                x-small
                                class="ml-1"
                                color="error"
                                @click.prevent="
                                  insuredRevisionHandle(insured_revision, false)
                                "
                              >
                                <v-icon> mdi-cancel </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Rechazar'" />
                          </v-tooltip>
                          <v-tooltip left>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                fab
                                x-small
                                class="ml-1"
                                color="success"
                                @click.prevent="
                                  insuredRevisionHandle(insured_revision, true)
                                "
                              >
                                <v-icon> mdi-check </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Aceptar'" />
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Banco"
                        :value="insured_revision.bank.bank"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Núm cuenta"
                        :value="insured_revision.account_number"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData label="CLABE" :value="insured_revision.clabe" />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Cuenta bancaria (PDF)"
                        :value="
                          insured_revision.bank_account_pdf &&
                          insured_revision.bank_account_pdf != 'null'
                            ? null
                            : '-'
                        "
                      />
                      <v-btn
                        v-if="
                          insured_revision.bank_account_pdf &&
                          insured_revision.bank_account_pdf != 'null'
                        "
                        x-small
                        fab
                        outlined
                        class="mr-1"
                        color="warning"
                        :href="
                          url_documents +
                          '/insureds/' +
                          insured_revision.bank_account_pdf
                        "
                        target="_blank"
                      >
                        <v-icon v-text="'mdi-download'" />
                      </v-btn>
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Valid. cuenta (PDF)"
                        :value="
                          insured_revision.account_validation_pdf &&
                          insured_revision.account_validation_pdf != 'null'
                            ? null
                            : '-'
                        "
                      />
                      <v-btn
                        v-if="
                          insured_revision.account_validation_pdf &&
                          insured_revision.account_validation_pdf != 'null'
                        "
                        x-small
                        fab
                        outlined
                        class="mr-1"
                        color="warning"
                        :href="
                          url_documents +
                          '/insureds/' +
                          insured_revision.account_validation_pdf
                        "
                        target="_blank"
                      >
                        <v-icon v-text="'mdi-download'" />
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  URL_DOCUMENTS,
  toFormData,
  rules,
} from "../../control";
import { show, destroy } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      rules: rules(),
      url_documents: URL_DOCUMENTS,
      contractor_id: this.$route.params.contractor_id,
      page_route: "asegurados",
      id: this.$attrs.id,
      login: this.$store.getters.getLogin,
      loading: true,
      data: null,
      contractor: {},
      loading_contractor: true,
      banks: [],
      banks_loading: true,
      insured_revisions: null,
      insured_revisions_data: null,
      insured_revisions_dialog_loading: false,
      insured_revisions_dialog: false,
      policyholder: null,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getData() {
      this.loading = true;

      show("insureds", this.login.token, this.id).then((response) => {
        this.data = response;

        if (this.data.insured_type_id != 1) {
          Axios.get(
            URL_API + "/insureds/policyholder/" + this.id,
            headersToken(this.login.token)
          ).then((res) => {
            this.policyholder = res.data.data;
            this.loading = false;
          });
        } else {
          this.loading = false;
        }
      });
    },
    HandleDesactivate() {
      this.$swal
        .fire(msgConfirm("Confirma la desactivación del registro?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;
            destroy("insureds", this.login.token, this.id, this.login.id).then(
              (response) => {
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                response.success
                  ? this.$router.push({ name: this.page_route })
                  : console.log(response.message);
              }
            );
          }
        });
    },
    insuredMedicalTreatmentHandle() {
      if (this.login.permissions.insureds.update) {
        this.$swal
          .fire(
            msgConfirm(
              "¿Confirma " +
                (this.data.medical_treatment ? "quitar de" : "poner en") +
                " tratamiento al asegurado?"
            )
          )
          .then((resp) => {
            if (resp.isConfirmed) {
              this.loading = true;

              Axios.post(
                URL_API + "/insureds/medical/treatment",
                {
                  id: this.data.id,
                },
                headersToken(this.login.token)
              ).then((resp) => {
                this.$swal.fire(
                  msgAlert(
                    resp.data.success ? "success" : "error",
                    resp.data.message
                  )
                );

                if (resp.data.success) {
                  this.data.medical_treatment = resp.data.medical_treatment;
                } else {
                  console.log(resp.data.message);
                }

                this.loading = false;
              });
            }
          });
      }
    },
    insuredRevisionsDialog() {
      this.insured_revisions = null;
      this.insured_revisions_data = {
        insured_id: this.id,
        bank_id: null,
        account_number: "",
        clabe: "",
        document_bank_account_0: null,
        document_account_validation_0: null,
      };
      this.insured_revisions_dialog_loading = false;
      this.insured_revisions_dialog = true;
    },
    insuredRevisionStore() {
      if (this.$refs.insured_revisions_form.validate()) {
        this.$swal
          .fire(msgConfirm("¿Confirma enviar revisión?"))
          .then((resp) => {
            if (resp.isConfirmed) {
              this.insured_revisions_dialog_loading = true;

              Axios.post(
                URL_API + "/insureds/revisions",
                toFormData(this.insured_revisions_data),
                headersToken(this.login.token)
              ).then((resp) => {
                this.$swal.fire(
                  msgAlert(
                    resp.data.success ? "success" : "error",
                    resp.data.message
                  )
                );

                if (resp.data.success) {
                  this.insured_revisions_dialog = false;
                  this.getData();
                } else {
                  console.log(resp.data.message);
                }

                this.insured_revisions_dialog_loading = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    insuredRevisionHandle(insured_revision, accepted) {
      this.$swal
        .fire(
          msgConfirm(
            "¿Confirma " +
              (accepted ? "aceptar" : " rechazar") +
              (insured_revision.revision == null ? " R1" : " R2") +
              "?"
          )
        )
        .then((resp) => {
          if (resp.isConfirmed) {
            this.insured_revisions_dialog_loading = true;

            Axios.post(
              URL_API +
                "/insureds/revisions/" +
                (insured_revision.revision == null
                  ? "revision"
                  : "confirmation"),
              {
                id: insured_revision.id,
                accepted: accepted,
                observation: "",
              },
              headersToken(this.login.token)
            ).then((resp) => {
              this.$swal.fire(
                msgAlert(
                  resp.data.success ? "success" : "error",
                  resp.data.message
                )
              );

              if (resp.data.success) {
                this.insured_revisions_dialog = false;
                this.getData();
              } else {
                console.log(resp.data.message);
              }

              this.insured_revisions_dialog_loading = false;
            });
          }
        });
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    Axios.get(
      `${URL_API}/catalog?name=banks&no_order=false`,
      headersToken(this.login.token)
    ).then((resp) => {
      this.banks = resp.data.data;
      this.banks_loading = false;
    });

    show("contractors", this.login.token, this.contractor_id).then(
      (response) => {
        this.contractor = response;
        this.loading_contractor = false;
      }
    );

    this.getData();
  },
};
</script>